<template>
  <div class="bg-white">
    <TransitionFade>
      <div class="flex flex-col">
        <div class="w-full xl:px-0">
          <!-- Logos -->
          <header class="xl:w-[1140px] mx-auto relative" style="padding-left: 3rem; padding-right: 0">
            <div class="py-0 grid grid-cols-2 justify-items-start place-items-center">
              <div class="xl:w-[634.422px] flex justify-start items-center">
                <img class="h-12 object-fit" src="@/assets/MercadoShops/Mailup/logo-mailup.jpg" alt="logo mailup" />
                <img class="h-32 object-fit" src="@/assets/MercadoShops/Mailup/logo-ms.png" alt="logo mercado shops" />
              </div>
            </div>

            <div class="absolute top-12 right-0">
              <SelectLangs :showLangs="['es', 'pt']" />
            </div>
          </header>

          <!-- banner -->
          <section class="h-48 flex flex-col justify-center items-center text-center ms-banner">
            <div>
              <h1 class="ms-title text-3xl lg:text-4xl font-bold" v-html="$t('landingPageMercadoShops.Unlimited contacts and campaigns')"></h1>
              <h4 class="mt-2 text-white text-lg lg:text-3xl font-light" v-html="$t('landingPageMercadoShops.With MailUp you can send the number of emails you wish to your entire client list')"></h4>
            </div>
          </section>

          <!-- user benefits -->
          <section class="mt-14 flex flex-row justify-between xl:w-[1140px] mx-auto md:px-2 lg:px-12">
            <div class="w-full flex flex-col-reverse md:flex-row justify-center items-center md:justify-between">
              <div class="text-center md:text-left md:w-3/4 h-full flex flex-col justify-between items-center md:items-start">
                <h2 class="lg:text-3xl xl:text-4xl font-bold" v-html="$t('landingPageMercadoShops.Being a Mercado Shops user has benefits')"></h2>
                <p class="mt-5 text-lg lg:text-xl font-light" v-html="$t('landingPageMercadoShops.p1t1')"></p>
                <h3 class="text-lg lg:text-2xl xl:text-3xl font-bold mt-2" v-html="$t('landingPageMercadoShops.p1t2')"></h3>
                <a href="https://setup.baymark.net/client/oauth2/redirect/mercadolibre/2?ignoreValidate=true" target="_blank" class="shadow font-bold ms-button transition-all mt-5 px-5 py-2">{{ $t("landingPageMercadoShops.Try the platform") }}</a>
              </div>

              <div class="md:w-2/6 flex justify-start items-center">
                <img class="w-full" src="@/assets/MercadoShops/Mailup/Imagen-1.png" alt="imagen descriptiva de beneficios" />
              </div>
            </div>
          </section>

          <!-- integrate banner -->
          <section class="my-32 xl:w-[1140px] mx-auto p-o flex flex-col justify-center items-center text-center">
            <h1 class="text-xl lg:text-4xl font-bold">{{ $t("landingPageMercadoShops.p2t1") }}</h1>
            <h4 class="mt-4 text-2xl lg:text-2xl font-light" v-html="$t('landingPageMercadoShops.p2t2')"></h4>

            <div class="mt-8 flex flex-col gap-y-5 md:gap-y-0 md:grid md:grid-cols-4 gap-x-12 lg:gap-x-20">
              <span class="flex flex-row md:flex-col justify-start items-center">
                <img class="h-24 w-24 object-contain mb-2" src="@/assets/MercadoShops/Mailup/chat.png" alt="Banner Icon 1" />
                <p class="ml-9 md:ml-0 font-semibold" v-html="$t('landingPageMercadoShops.p2support')"></p>
              </span>

              <span class="flex flex-row md:flex-col justify-start items-center">
                <img class="h-24 w-24 object-contain mb-2" src="@/assets/MercadoShops/Mailup/global.png" alt="Banner Icon 2" />
                <p class="ml-7 md:ml-0 font-semibold" v-html="$t('landingPageMercadoShops.p2certifications')"></p>
              </span>

              <span class="flex flex-row md:flex-col justify-start items-center">
                <img class="h-24 w-24 object-contain mb-2" src="@/assets/MercadoShops/Mailup/Facturacion.jpg" alt="Banner Icon 3" />
                <span class="ml-7 md:ml-0 flex flex-col justify-center items-center">
                  <p class="font-semibold">{{ $t("landingPageMercadoShops.p2localbilling") }}</p>
                  <small class="font-light">*AR | MX | CL | CO | BR</small>
                </span>
              </span>

              <span class="flex flex-row md:flex-col justify-start items-center">
                <img class="h-24 w-24 object-contain mb-2" src="@/assets/MercadoShops/Mailup/performance.png" alt="Banner Icon 4" />
                <span class="ml-7 md:ml-0 flex flex-col justify-center items-center">
                  <p class="font-semibold" v-html="$t('landingPageMercadoShops.p2businessmodel')"></p>
                  <small class="font-light">*{{ $t("landingPageMercadoShops.p2businessmodelvelocity") }}</small>
                </span>
              </span>
            </div>
          </section>

          <!-- sync-->
          <section class="my-20 flex flex-row justify-between xl:w-[1140px] mx-auto md:pr-5 lg:pr-12">
            <div class="w-full flex flex-col-reverse md:flex-row justify-center items-center md:justify-between">
              <div class="md:w-2/4 flex justify-start items-center">
                <template v-if="$store.state.Langs?.selected?.lang == 'pt'">
                  <img class="w-full" src="@/assets/MercadoShops/Mailup/orden_de_compra_pt.gif" alt="imagen descriptiva de Sincronización" />
                </template>
                <template v-else>
                  <img class="w-full" src="@/assets/MercadoShops/Mailup/orden_de_compra.gif" alt="imagen descriptiva de Sincronización" />
                </template>
              </div>
              <div class="text-center md:text-right md:w-2/4 h-full flex flex-col justify-between items-center md:items-end">
                <h2 class="text-lg lg:text-3xl xl:text-4xl font-bold" v-html="$t('landingPageMercadoShops.p3title')"></h2>
                <p class="mt-5 text-lg lg:text-xl font-light" v-html="$t('landingPageMercadoShops.p3t1')"></p>
                <a href="https://setup.baymark.net/client/oauth2/redirect/mercadolibre/2?ignoreValidate=true" target="_blank" class="uppercase shadow ms-button-outline transition-all mt-5 px-5 py-2">{{ $t("landingPageMercadoShops.Integrate your store") }}</a>
              </div>
            </div>
          </section>

          <!-- products -->
          <section class="my-20 flex flex-row justify-between xl:w-[1140px] mx-auto md:pl-5 lg:pl-8">
            <div class="w-full flex flex-col md:flex-row justify-center items-center md:justify-between">
              <div class="text-center md:text-left md:w-2/4 h-full flex flex-col justify-center items-start">
                <h2 class="text-lg lg:text-3xl xl:text-4xl font-bold">{{ $t("landingPageMercadoShops.p4title") }}</h2>
                <p class="mt-5 text-lg lg:text-xl font-light" v-html="$t('landingPageMercadoShops.p4t1')"></p>
              </div>
              <div class="md:w-2/4 flex justify-end items-center">
                <img class="flex flex-start justify-start w-3/5 mr-20" src="@/assets/MercadoShops/Mailup/catalogo-productos.png" alt="imagen descriptiva de MailUp" />
              </div>
            </div>
          </section>

          <!-- forms -->
          <section class="mt-20 flex flex-row justify-between xl:w-[1140px] mx-auto md:pr-5 lg:pr-12">
            <div class="w-full flex flex-col-reverse md:flex-row justify-center items-center md:justify-between">
              <div class="md:w-2/4 flex justify-start items-center">
                <img class="w-3/4 -mt-10" src="@/assets/MercadoShops/Mailup/Imagen-7.png" alt="imagen descriptiva de creacion de formularios" />
              </div>
              <div class="text-center md:text-right md:w-2/4 h-full flex flex-col items-center md:items-end">
                <h2 class="text-lg lg:text-3xl xl:text-4xl font-bold" v-html="$t('landingPageMercadoShops.p5title')"></h2>
                <p class="mt-5 text-lg lg:text-xl font-light" v-html="$t('landingPageMercadoShops.p5t1')"></p>
                <a href="https://setup.baymark.net/client/oauth2/redirect/mercadolibre/2?ignoreValidate=true" target="_blank" class="uppercase shadow ms-button-outline transition-all mt-5 px-5 py-2">{{ $t("landingPageMercadoShops.Meet the platform") }}</a>
              </div>
            </div>
          </section>

          <!-- modules -->
          <section class="my-0 flex flex-row justify-between xl:w-[1140px] mx-auto md:pl-5 lg:pl-8">
            <div class="w-full flex flex-col md:flex-row justify-center items-center md:justify-between">
              <div class="text-center md:text-left md:w-2/4 h-full flex flex-col justify-center items-center md:items-start">
                <h2 class="text-lg lg:text-3xl xl:text-4xl font-bold">{{ $t("landingPageMercadoShops.p6title") }}</h2>
                <p class="mt-5 text-lg lg:text-xl font-light" v-html="$t('landingPageMercadoShops.p6t1')"></p>
              </div>
              <div class="md:w-2/4 flex justify-end items-center">
                <img class="w-4/5" src="@/assets/MercadoShops/Mailup/Imagen-8.jpg" alt="imagen descriptiva de Modulos" />
              </div>
            </div>
          </section>

          <!-- support banner -->
          <section class="my-20 h-[45em] lg:h-96 xl:h-80 flex flex-col justify-center items-center text-center ms-support-banner lg:p-12">
            <div class="my-8 flex flex-col md:flex lg:flex-row justify-between items-center xl:w-[1140px] mx-auto md:pl-5 lg:pl-8">
              <div class="grid gap-y-3 text-center lg:text-left">
                <h2 class="text-lg lg:text-xl xl:text-3xl font-bold ms-support-title">{{ $t("landingPageMercadoShops.p7title") }}</h2>
                <p class="mt-5 text-base xl:text-lg font-light" v-html="$t('landingPageMercadoShops.p7t1')"></p>
                <span class="font-semibold">{{ $t("landingPageMercadoShops.p7t2") }}</span>
              </div>
              <hr style="height: 1px; width: 200px; border: 1px solid #0d3953" class="my-12 lg:hidden" />
              <hr class="hidden lg:block ms-support-line" />
              <div class="h-full grid text-left">
                <ul class="grid gap-y-3">
                  <li>
                    <span class="inline-flex justify-center items-center">
                      <img src="@/assets/MercadoShops/Mailup/supporto_tipo_icon_prioritario.png" class="w-8" alt="icono de consultas" />
                      <p class="ml-3 ms-support-item font-bold">{{ $t("landingPageMercadoShops.p8title1") }}</p>
                    </span>
                  </li>
                  <li>
                    <span class="inline-flex justify-center items-center">
                      <img src="@/assets/MercadoShops/Mailup/supporto_tipo_icon_custom.png" class="w-8" alt="icono de Asistencia" />
                      <p class="ml-3 ms-support-item font-bold">{{ $t("landingPageMercadoShops.p8title2") }}</p>
                    </span>
                  </li>
                  <li>
                    <span class="inline-flex justify-center items-center">
                      <img src="@/assets/MercadoShops/Mailup/TILDE.png" class="w-8" alt="icono de consultas" />
                      <p class="ml-3 ms-support-item font-bold">{{ $t("landingPageMercadoShops.p8title3") }}</p>
                    </span>
                  </li>
                  <li>
                    <span class="inline-flex justify-center items-center">
                      <img src="@/assets/MercadoShops/Mailup/IDEA.png" class="w-9" alt="icono de Tutoriales" />
                      <p class="ml-2 ms-support-item font-bold">{{ $t("landingPageMercadoShops.p8title4") }}</p>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <!-- footer -->
          <section class="mt-20 mb-12 flex flex-col justify-center items-center">
            <h2 class="ms-support-title font-bold text-lg lg:text-2xl xl:text-4xl text-center" v-html="$t('landingPageMercadoShops.p9title1')"></h2>
            <br />
            <p class="text-base lg:text-lg text-center" v-html="$t('landingPageMercadoShops.p9title2')"></p>
            <br />
            <a href="https://setup.baymark.net/client/oauth2/redirect/mercadolibre/2?ignoreValidate=true" target="_blank" class="font-bold shadow ms-button transition-all mt-3 px-5 py-2">{{ $t("landingPageMercadoShops.Try the platform") }}</a>
            <br />
          </section>
        </div>
      </div>
    </TransitionFade>
  </div>
</template>

<script>
import TransitionFade from "@/components/TransitionFade"
import SelectLangs from "@/components/SelectLangs"

export default {
  name: "WelcomeMailUp",
  components: {
    TransitionFade,
    SelectLangs
  }
}
</script>
